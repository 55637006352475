/* src/components/OffersAndRates.css */

.offers-and-rates {
  text-align: center;
  margin: 2rem 0;
}

.offers-container {
  display: flex;
  justify-content: center;
  gap: 2rem; /* Espace entre les cartes */
  flex-wrap: wrap; /* Pour que les cartes se déplacent sur plusieurs lignes si nécessaire */
}

.offer-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1.5rem;
  width: 200px; /* Largeur fixe pour les cartes */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.offer-card:hover {
  transform: translateY(-5px); /* Effet d'élévation au survol */
}

.price {
  font-size: 1.5rem;
  font-weight: bold;
  color: #007bff; /* Couleur pour le prix */
}
