.why-choose-sosbox {
    display: flex;
    align-items: center; /* Aligne le contenu verticalement */
    justify-content: space-between; /* Espace entre le texte et l'image */
    margin: 1rem 0; /* Ajoute de la marge en haut et en bas */
}

.text-content {
    display: flex; /* Active Flexbox */
    flex-direction: column; /* Aligne les éléments en colonne */
    justify-content: center; /* Centre verticalement */
    align-items: center; /* Centre horizontalement */
    flex: 1; /* Prend l'espace restant */
    padding: 1rem; /* Rembourrage autour du texte */
    padding-right: 2rem; /* Espace entre le texte et l'image */
    text-align: center; /* Centre le texte horizontalement */
    background-color: rgba(255, 255, 255, 0.8); /* Fond blanc légèrement transparent */
    border-radius: 8px; /* Coins arrondis pour un effet doux */
}

.text-content h2 {
    font-size: 2rem; /* Taille du titre */
    margin-bottom: 2.5rem; /* Espace sous le titre */
    color: #333; /* Couleur du titre */
    margin: 0; /* Supprime les marges à gauche */
    margin-bottom: 1rem;
}

.text-content p {
    font-size: 1.125rem; /* Taille de police */
    line-height: 1.6; /* Espacement entre les lignes */
    margin-top: 0.5rem 0; /* Marges en haut et en bas du paragraphe */
    color: #555; /* Couleur du texte */
    max-width: 600px; /* Largeur maximale pour contrôler l'étalement */
    text-align: center; /* Centre le texte horizontalement */
}


.image-content img {
    max-width: 100%; /* S'assure que l'image ne déborde pas */
    border-radius: 8px; /* Arrondit les coins de l'image */
    margin-bottom: 3rem;
}

/* Médias Queries pour mobile */
@media (max-width: 768px) {
    .why-choose-sosbox {
        flex-direction: column; /* Aligne les éléments verticalement */
        align-items: center; /* Centre les éléments horizontalement */
    }

    .text-content {
        display: flex;
        flex-direction: column;
        justify-content: center; /* Centre verticalement */
        align-items: center; /* Centre horizontalement */
        padding: 1rem; /* Ajuste le padding si nécessaire */
        text-align: center; /* Centre le texte */
    }

    .text-content h2 {
        font-size: 1.8rem; /* Ajuste la taille si nécessaire */
        margin: 0; /* Évite les marges qui peuvent décentrer le titre */
        margin-bottom: 1.5rem; /* Réduit l'espace sous le titre */
    }
    

    .text-content p {
        font-size: 1rem; /* Réduit la taille de la police sur mobile */
        margin-left: 0; /* Enlève la marge à gauche */
        max-width: 80%; /* Permet au texte de s'étendre sur toute la largeur */
    }

    .image-content {
        margin-top: 1rem; /* Ajoute un espacement au-dessus de l'image */
    }
    
}
