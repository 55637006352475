:root {
    --turquoise: #0266ce;
    --white: #FFFFFF;
    --black: #000000;
}

nav {
    display: flex; /* Utilise Flexbox pour aligner les éléments sur une ligne */
    justify-content: space-between; /* Espace égal entre les éléments */
    align-items: center; /* Centre les éléments verticalement */
    padding: 0.5rem 2rem; /* Rembourrage pour le nav */
    background-color: #fcfcfc; /* Couleur de fond */
    width: 100%; /* Assure que la barre de navigation prend toute la largeur */
}

.nav-container {
    display: flex;
    justify-content: flex-start; /* Commence à gauche */
    align-items: center; /* Centre les éléments verticalement */
    width: 100%; /* Assure que le conteneur prend toute la largeur */
    margin: 0 auto; /* Centre le conteneur */
    padding: 0; /* Enlève le padding si nécessaire */
}

.logo {
    text-decoration: none; /* Enlève le soulignement */
    font-size: 1.8rem;
    font-weight: bold;
    color: #0266ce;
    margin-right: 1.5rem; /* Espace à droite */
}

.nav-phone {
    color: var(--black);
    font-weight: bold;
    font-size: 1rem; /* Ajuster la taille si nécessaire */
    margin-right: 1.5rem; /* Un peu d'espace à droite */
    margin-left: 2rem;
}

.nav-menu {
    display: flex;
    list-style-type: none;
    margin-left: auto; /* Pousse le menu à droite */
}

.nav-menu li {
    margin-left: 1.5rem; /* Ajuste l'espacement entre les éléments de la liste */
}

.nav-menu a {
    color: var(--black);
    text-decoration: none;
    transition: color 0.3s ease;
}

.nav-menu a:hover {
    color: #0266ce;
}

/* Styles pour le bouton hamburger */
.nav-toggle {
    display: none; /* Cacher par défaut */
}

.nav-toggle .bar {
    height: 3px; /* Épaisseur des barres */
    width: 25px; /* Largeur des barres */
    background-color: #333; /* Couleur des barres */
    margin: 4px 0; /* Espacement entre les barres */
}

/* Médias Queries pour mobile */
@media (max-width: 768px) {
    .nav-container {
        flex-direction: row; /* Aligne les éléments horizontalement */
        align-items: center; /* Aligne les éléments au centre verticalement */
        justify-content: space-between; /* Espace entre le logo et le toggle */
        padding: 0 1rem; /* Ajoute un peu de padding pour l'espacement */
    }

    .logo {
        margin-left: 0; /* Enlève toute marge à gauche */
        position: absolute; /* Positionne le logo de façon absolue */
        left: 1rem; /* Décale le logo à gauche avec une marge de 1rem */
    }

    .nav-menu {
        display: none; /* Cacher le menu par défaut */
        flex-direction: column; /* Aligne les éléments du menu verticalement */
        width: 100%; /* Prend toute la largeur */
        position: absolute; /* Position absolue pour le menu */
        top: 2rem; /* Ajuste la position par rapport à la navbar */
        left: 0;
        background-color: #e4e4e4; /* Couleur de fond */
        z-index: 1; /* Assure que le menu est au-dessus d'autres éléments */
        padding: 1rem 0; /* Ajoute du padding pour l'espacement autour du menu */
    }
    
    .nav-menu li {
        margin: 0.5rem 0; /* Ajoute un espacement vertical entre les éléments du menu */
        display: flex; /* Utilise flexbox pour centrer le contenu */
        justify-content: center; /* Centre horizontalement */
        align-items: center; /* Centre verticalement */
        width: 100%; /* Prend toute la largeur pour le centrage */
        text-align: center; /* Centre le texte des éléments de menu, si nécessaire */
    }

    .nav-menu.active {
        display: flex; /* Affiche le menu lorsqu'il est actif */
    }

    .nav-toggle {
        display: flex; /* Affiche le bouton toggle */
        flex-direction: column; /* Aligne les barres verticalement */
        margin-left: 1.8rem; /* Espace à gauche pour le toggle */
        cursor: pointer; /* Change le curseur */
    }

    /* Style pour le numéro de téléphone */
    .nav-phone {
        font-size: 1.0rem; /* Agrandit le numéro de téléphone */
        white-space: nowrap; /* Empêche le retour à la ligne */
        margin-left: 9.0rem; /* Espace à gauche pour le numéro */
        text-align: left; /* Aligne le numéro à gauche */
    }
}

