/* ReservationForm.css */
.reservation-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    background-color: #f9f9f9;
  }
  
  .reservation-form {
    width: 100%;
    max-width: 600px;
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative; /* Pour positionner la croix par rapport à ce conteneur */
  }
  
  .close-button {
    position: absolute; /* Positionnement absolu */
    top: 10px; /* Ajuster selon l'espace désiré */
    right: 10px; /* Ajuster selon l'espace désiré */
    background: none; /* Pas de fond */
    border: none; /* Pas de bordure */
    font-size: 0.5rem; /* Taille de la croix */
    cursor: pointer; /* Changer le curseur au survol */
    color: #333; /* Couleur de la croix */
  }
  
  .reservation-form input,
  .reservation-form select,
  .reservation-form textarea {
    width: 100%;
    padding: 0.75rem;
    margin: 0.5rem 0;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .reservation-form button {
    padding: 0.75rem 1.5rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .reservation-form button:hover {
    background-color: #0056b3; /* Légère modification de la couleur au survol */
  }
  