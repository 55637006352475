.map-section {
    padding: 4rem 0; /* Ajoute un peu d'espace en haut et en bas */
    text-align: center; /* Centre le texte dans la section */
    background-color: #f9f9f9; /* Couleur de fond légère */
}

.container {
    max-width: 1200px; /* Largeur maximale du conteneur */
    margin: 0 auto; /* Centre le conteneur horizontalement */
    padding: 0 1rem; /* Rembourrage latéral */
}

.map-title {
    font-size: 2rem; /* Taille du titre */
    margin-bottom: 1rem; /* Espace sous le titre */
    color: #333; /* Couleur du titre */
    text-align: center; /* Centre le texte dans la section */
}

.map-description {
    font-size: 1.2rem; /* Taille du texte de la description */
    margin-bottom: 2rem; /* Espace sous le paragraphe */
    color: #666; /* Couleur du texte de la description */
}

/* Médias Queries pour mobile */
@media (max-width: 768px) {
    .map-title {
        font-size: 1.5rem; /* Réduire la taille du titre sur mobile */
        margin-top: 1rem;
    }

    .map-description {
        font-size: 1rem; /* Réduire la taille du texte de description */
        margin-bottom: 1.5rem; /* Ajuster l'espace sous le paragraphe */
    }

    .container {
        padding: 0 0.5rem; /* Réduire le rembourrage latéral sur mobile */
    }

    .map-section {
        padding: 2rem 0; /* Réduire le padding vertical sur mobile */
    }
}
