.contact-form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.contact-form-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.contact-form-container input,
.contact-form-container textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s;
}

.contact-form-container input:focus,
.contact-form-container textarea:focus {
  border-color: #007bff; /* Couleur de la bordure lors du focus */
  outline: none; /* Supprimer le contour par défaut */
}

.contact-form-container button {
  width: 100%;
  padding: 10px;
  background-color: #0266ce; /* Couleur de fond du bouton */
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-form-container button:hover {
  background-color: #218838; /* Couleur de fond du bouton lors du survol */
}

.contact-form-container p {
  text-align: center;
  color: #28a745; /* Couleur du texte de succès */
  margin-top: 10px;
}

.contact-form-container p.error {
  color: #dc3545; /* Couleur du texte d'erreur */
}
