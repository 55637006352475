.services-section {
    padding: 4rem 0;
    text-align: center;
    background-color: #f9f9f9; /* Couleur de fond claire */
}

.services-title {
    font-size: 2rem;
    margin-bottom: 2rem;
    margin-top: -5rem;
}

.services-list {
    display: flex;
    justify-content: center; /* Centrer les vignettes */
    flex-wrap: wrap; /* Pour un meilleur affichage sur mobile */
}

.service-item {
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 1.5rem;
    margin: 1rem;
    width: 25%; /* Ajuster la largeur selon le design souhaité */
    max-width: 300px; /* Limiter la largeur maximum des vignettes */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.service-item h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.service-item p {
    font-size: 1rem;
    color: #666; /* Couleur de texte douce */
}

/* Médias Queries pour mobile */
@media (max-width: 768px) {
    .services-title {
        font-size: 1.8rem; /* Réduire la taille du titre */
        margin-top: -4rem;
    }

    .service-item {
        width: 100%; /* Prendre toute la largeur sur mobile */
        max-width: none; /* Enlever la largeur maximale pour mobile */
        margin: 0.5rem 0; /* Réduire l'espace entre les vignettes */
        
    }

    .services-list {
        justify-content: center; /* Centrer les vignettes */
        margin-bottom: -3rem;
    }
}
