.hero {
    height: 100vh;
    background-image: url('https://i.postimg.cc/43f9pgPX/hero-image.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--white);
}

.hero-content h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.hero-content p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
}

.btn {
    display: inline-block;
    background-color: var(--turquoise);
    color: var(--white);
    padding: 0.8rem 1.5rem;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.btn:hover {
    background-color: #0266ce;
}

.btn-group {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.hero-content .btn-group {
    margin-top: 2rem;
}

/* Médias Queries pour mobile */
@media (max-width: 768px) {
    .hero {
        height: 60vh; /* Réduire la hauteur pour les écrans plus petits */
        padding: 2rem 1rem; /* Ajouter un peu de padding pour les petits écrans */
    }

    .hero-content h1 {
        font-size: 2.0rem; /* Réduire la taille du titre */
    }

    .hero-content p {
        font-size: 0.9rem; /* Réduire la taille du texte du paragraphe */
    }

    .btn {
        padding: 0.6rem 1rem; /* Ajuster le padding des boutons */
    }
}
