.testimonials-section {
    padding: 4rem 0;
    background-color: #f9f9f9;
    text-align: center;
}

.testimonials-title {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    text-align: center;
}

.testimonial-item {
    background: #fff;
    border-radius: 8px;
    padding: 1.5rem;
    margin: 1rem auto;
    max-width: 600px; /* Largeur maximale pour le texte */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.testimonial-item p {
    font-size: 1rem;
    color: #666;
    margin-bottom: 1rem;
}

.testimonial-item h4 {
    font-size: 1.25rem;
    font-weight: bold;
}

/* Médias Queries pour mobile */
@media (max-width: 768px) {
    .testimonials-section {
        padding: 2rem 1rem; /* Réduit le padding */
    }

    .testimonials-title {
        font-size: 1.5rem; /* Réduit la taille du titre */
    }

    .testimonial-item {
        padding: 1rem; /* Réduit le padding à l'intérieur des témoignages */
        margin: 0.5rem auto; /* Ajuste les marges pour un meilleur espacement */
        max-width: 90%; /* Largeur maximale pour les mobiles */
    }

    .testimonial-item p {
        font-size: 0.9rem; /* Réduit la taille de la police pour le texte */
    }

    .testimonial-item h4 {
        font-size: 1.1rem; /* Réduit la taille de la police pour le nom */
    }
}