.faq {
    padding: 2rem 0;
    background-color: #f9f9f9; /* Couleur de fond claire */
}

.faq-title {
    font-size: 2rem; /* Taille du texte */
    font-weight: bold; /* Gras */
    text-align: center; /* Centrer le texte */
    margin-bottom: 2rem; /* Espacement en bas */
    margin-top: -0.8rem;
    color: black ; 
}


.faq-list {
    margin-top: 1rem;
}

.faq-item {
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin-bottom: 1rem;
    padding: 1rem;
    transition: background 0.3s;
}

.faq-item:hover {
    background: #f0f0f0; /* Changement de couleur au survol */
}

.faq-question {
    font-size: 1.2rem;
    font-weight: bold;
    display: flex;
    justify-content: space-between; /* Espace entre la question et la flèche */
    align-items: center; /* Centre les éléments verticalement */
}

.arrow {
    margin-left: 1rem; /* Espacement à gauche de la flèche */
    font-size: 1.2rem; /* Taille de la flèche */
    transition: transform 0.3s; /* Animation pour la flèche */
}

.faq-answer {
    margin-top: 0.5rem; /* Espacement au-dessus de la réponse */
    font-size: 1rem;
    color: #666; /* Couleur de texte douce */
}

/* Médias Queries pour mobile */
@media (max-width: 768px) {
    .faq {
        padding: 1rem 0; /* Réduit le padding pour mobile */
    }

    .faq-title {
        font-size: 1.5rem; /* Réduit la taille du titre */
        margin-bottom: 1rem; /* Réduit l'espace sous le titre */
    }

    .faq-item {
        padding: 0.8rem; /* Réduit le padding des items */
    }

    .faq-question {
        font-size: 1rem; /* Réduit la taille de la police de la question */
    }

    .arrow {
        font-size: 1rem; /* Réduit la taille de la flèche */
    }

    .faq-answer {
        font-size: 0.9rem; /* Réduit la taille de la police de la réponse */
    }
}