/* Style global */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin); /* Texte adaptatif */
  color: white;
  text-align: center; /* Centre le texte */
  padding: 1rem; /* Ajoute un peu d'espacement interne */
}

/* Liens */
.App-link {
  color: #61dafb;
  text-decoration: none;
  font-size: 1rem; /* Taille du texte cohérente */
  margin: 1rem 0;
  display: inline-block;
  text-align: center;
}

/* Animation du logo */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Boutons */
.btn-group {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Permet de passer en ligne suivante si nécessaire */
  margin: 2rem 0; /* Espacement en haut et en bas */
  gap: 1rem; /* Ajoute de l'espace entre les boutons */
}

.btn {
  background-color: #61dafb;
  color: #fff;
  border: none;
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: bold;
  text-align: center; /* Centre le texte dans le bouton */
  border-radius: 8px; /* Coins arrondis pour un style moderne */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #21a1f1; /* Couleur légèrement plus foncée au survol */
}

.btn:active {
  background-color: #1e90ff; /* Couleur encore plus foncée au clic */
}

/* Conteneur principal */
.outer-container {
  overflow-x: hidden; /* Empêche le débordement horizontal */
  width: 100%; /* Utilise toute la largeur disponible */
  padding: 1rem; /* Ajoute un peu d'espace interne */
}

/* Section de l'image */
.image-content1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0; /* Espacement global au-dessus et au-dessous */
  height: auto; /* Laisse le contenu s'adapter naturellement */
  text-align: center; /* Centre les textes si nécessaire */
}

.image-content1 img {
  max-width: 100%;
  height: auto;
  border-radius: 10px; /* Ajoute un effet de bord arrondi */
}

/* Titres et Paragraphes */
h1, h2, h3, p {
  margin: 1rem 0;
  text-align: center;
  line-height: 1.5; /* Meilleure lisibilité */
}

/* Responsiveness */

/* Tablettes */
@media (max-width: 768px) {
  .App-header {
    font-size: calc(12px + 1.5vmin); /* Taille du texte légèrement réduite */
    padding: 2rem;
  }

  .btn-group {
    flex-direction: column; /* Aligne les boutons en colonne */
    align-items: center;
    gap: 1rem; /* Ajoute de l'espace vertical entre les boutons */
  }

  .btn {
    width: 80%; /* Les boutons occupent 80% de la largeur */
    max-width: 300px; /* Taille maximale */
  }

  .image-content1 img {
    max-width: 80%; /* Réduit la largeur de l'image */
  }
}

/* Mobiles */
@media (max-width: 480px) {
  .App-header {
    font-size: calc(12px + 1vmin); /* Réduit encore légèrement la taille du texte */
    padding: 1.5rem;
  }

  .btn {
    width: 90%; /* Les boutons occupent presque toute la largeur */
    padding: 0.8rem 1.5rem; /* Boutons plus compacts */
  }

  h1, h2, h3 {
    font-size: 1.2rem; /* Ajuste la taille des titres */
  }

  p {
    font-size: 1rem; /* Ajuste la taille des paragraphes */
  }

  .image-content1 img {
    width: 100%; /* Image occupe toute la largeur */
    max-width: 280px; /* Taille maximale */
  }
}
