.questions-section {
    padding: 4rem 0;
    background-color: #fff;
    margin-bottom: -2rem;
}

.questions-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 2rem;
    margin-top: -3rem;
}

.question-item {
    margin: 1rem 0;
    padding: 1.5rem;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    transition: background-color 0.3s;
}

.question-item:hover {
    background-color: #f1f1f1; /* Changement de couleur au survol */
}

.question-item h4 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
}

.question-item p {
    font-size: 1rem;
    color: #666;
}

/* Médias Queries pour mobile */
@media (max-width: 768px) {
    .questions-section {
        padding: 2rem 1rem; /* Réduit le padding pour mobile */
    }

    .questions-title {
        font-size: 1.8rem; /* Réduit la taille du titre */
        margin-bottom: 1.5rem; /* Réduit l'espace sous le titre */
        margin-top: -2rem; /* Ajuste le margin-top si nécessaire */
    }

    .question-item {
        padding: 1rem; /* Réduit le padding des items */
        margin: 0.5rem 0; /* Réduit la marge entre les items */
    }

    .question-item h4 {
        font-size: 1.1rem; /* Réduit la taille du sous-titre */
    }

    .question-item p {
        font-size: 0.9rem; /* Réduit la taille du texte */
    }
}
