/* Overlay pour couvrir toute la page */
.rgpd-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Style de la popup */
.rgpd-popup {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 500px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Conteneur des boutons */
.rgpd-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

/* Bouton accepter */
.rgpd-accept-button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.rgpd-accept-button:hover {
  background-color: #45a049;
}

/* Bouton refuser */
.rgpd-decline-button {
  padding: 10px 20px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.rgpd-decline-button:hover {
  background-color: #d7322e;
}

/* Lien dans le texte */
.rgpd-popup a {
  color: #007bff;
  text-decoration: none;
}

.rgpd-popup a:hover {
  text-decoration: underline;
}
