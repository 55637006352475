.footer {
    background-color: #f8f9fa; /* Couleur de fond claire */
    padding: 2rem 0; /* Rembourrage vertical */
    text-align: center; /* Centre le texte */
    color: #343a40; /* Couleur du texte */
}

.container {
    max-width: 1200px; /* Largeur maximale */
    margin: 0 auto; /* Centre le contenu */
    padding: 0 1rem; /* Rembourrage horizontal */
}

.footer-links {
    margin-bottom: 1rem; /* Espacement sous les liens */
}

.footer-links a {
    margin: 0 1rem; /* Espacement entre les liens */
    text-decoration: none; /* Enlève le soulignement */
    color: #0266ce; /* Couleur du texte des liens */
    transition: color 0.3s; /* Effet de transition sur la couleur */
}

.footer-links a:hover {
    color: #0266ce; /* Couleur au survol */
}
