.devis-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    min-height: 100vh; /* Pour centrer verticalement */
    background-color: #f9f9f9;
  }
  
  .devis-form {
    width: 100%;
    max-width: 600px;
    position: relative; /* Pour positionner la croix */
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: none;
    border: none;
    font-size: 0.5rem;
    cursor: pointer;
    color: #333;
  }
  
  .close-button:hover {
    color: #007bff; /* Change la couleur de la croix au survol */
  }
  
  .devis-form input,
  .devis-form textarea {
    width: 100%;
    padding: 0.75rem;
    margin: 0.5rem 0;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .devis-form button {
    padding: 0.75rem 1.5rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .devis-form button:hover {
    background-color: #0056b3;
  }
  