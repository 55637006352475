/* src/components/TermsOfUse.css */
.terms-of-use {
  padding: 2rem;
  max-width: 800px; /* Largeur maximale pour le contenu */
  margin: 0 auto; /* Centrer le contenu */
  background-color: #f9f9f9; /* Fond léger */
  border-radius: 8px; /* Coins arrondis */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Légère ombre */
}

.terms-of-use h1 {
  font-size: 2rem; /* Taille du titre principal */
  margin-bottom: 1rem; /* Marge sous le titre */
}

.terms-of-use h2 {
  font-size: 1.5rem; /* Taille des sous-titres */
  margin-top: 1.5rem; /* Marge au-dessus des sous-titres */
}

.terms-of-use p {
  font-size: 1rem; /* Taille du texte */
  line-height: 1.6; /* Espacement entre les lignes */
  margin: 0.5rem 0; /* Marges pour les paragraphes */
}
