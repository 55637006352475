.need-help {
    padding: 4rem 0;
    background-color: #f1f1f1; /* Couleur de fond claire */
    text-align: center;
}

.container {
    max-width: 800px;
    margin: 0 auto;
}

h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
    margin-top: -2rem;
}

.help-card {
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
}

.help-card h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.help-card p {
    font-size: 1rem;
    color: #666; /* Couleur de texte douce */
}

.btn {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    background-color: #007bff; /* Couleur de bouton */
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
}

.btn:hover {
    background-color: #0056b3; /* Couleur de survol du bouton */
}

/* Médias Queries pour mobile */
@media (max-width: 768px) {
    .need-help {
        padding: 2rem 0; /* Réduit le padding */
    }

    .container {
        max-width: 100%; /* Permet au conteneur de prendre toute la largeur sur mobile */
        padding: 0 1rem; /* Ajoute du padding latéral pour éviter que le texte touche les bords */
    }

    h2 {
        font-size: 1.5rem; /* Réduit la taille du titre */
        margin-bottom: 1.5rem; /* Réduit l'espacement sous le titre */
        margin-top: -1rem;
    }

    .help-card {
        padding: 1.5rem; /* Réduit le padding à l'intérieur des cartes */
        margin: 0.5rem auto; /* Ajuste les marges pour éviter un trop grand espacement */
    }

    .help-card h3 {
        font-size: 1.25rem; /* Réduit la taille du titre des cartes */
    }

    .help-card p {
        font-size: 0.9rem; /* Réduit la taille de la police pour le texte */
    }

    .btn {
        padding: 0.5rem 1rem; /* Réduit le padding du bouton */
    }
}